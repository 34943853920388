import {
    Box,
    Heading,
    Container,
    Text,
    Button,
    Stack,
    Highlight
} from '@chakra-ui/react';
import { useContext } from "react";
import { InboundSalesContext } from "../InboundSales";
export { Head } from "../seo";

export default function CommercialRealEstate() {
    const { onOpen } = useContext(InboundSalesContext);
    return (
        <>
            <Box bg="gray.50">
                <Container maxW={'3xl'} >
                    <Stack
                        as={Box}
                        textAlign={'left'}
                        spacing={{ base: 8, md: 14 }}
                        py={12}
                    >
                        <Heading
                            textTransform={'uppercase'}
                            color={'blue.400'}
                            fontWeight={600}
                            fontSize="md"
                            as="h1"
                            bg={'blue.50'}
                            p={2}
                            alignSelf={'flex-start'}
                            rounded={'md'}>
                            Parkify for Commercial Real Estate
                        </Heading>
                        <Heading
                            fontWeight={800}
                            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                            as="h2"
                        >
                            <Highlight
                                query='Net Operating Income'
                                styles={{ color: "purple.500" }}
                            >
                                Increase Net Operating Income
                            </Highlight>
                        </Heading>
                        <Text fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
                            Parkify brings more revenue into your parking facility by putting crucial parking information
                            exactly where parkers need it.
                        </Text>
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={3}
                        >
                            <Button
                                variant={'outline'}
                                rounded="lg"
                                colorScheme="purple"
                                onClick={onOpen}
                            >
                                Contact Us
                            </Button>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box bgGradient='linear(to-t, brand.200, brand.500)'>
                <Container maxW={'3xl'} >
                    <Stack
                        as={Box}
                        textAlign={'left'}
                        spacing={{ base: 8, md: 14 }}
                        py={12}
                    >
                        <Heading
                            fontWeight={800}
                            as="h2"
                            fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                        >
                            <Highlight
                                query='facilities experience'
                                styles={{ color: "gold.500" }}
                            >
                                Improve the facilities experience for your parkers
                            </Highlight>
                        </Heading>
                        <Text fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
                            Visitors to your facility start their experience from their phones, well before they step foot on
                            the property. Parkify gives first-time visitors confidence that they can park at your facility.
                        </Text>
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={3}
                        >
                            <Button
                                variant={'outline'}
                                rounded="lg"
                                colorScheme="black"
                                onClick={onOpen}
                            >
                                Contact Us
                            </Button>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
            <Box bgGradient='linear(to-t, purple.200, purple.500)'>
                <Container maxW={'3xl'}>
                    <Stack
                        as={Box}
                        textAlign={'left'}
                        spacing={{ base: 8, md: 14 }}
                        py={12}
                    >
                        <Heading
                            fontWeight={700}
                            color={"gray.50"}
                            as="h2"
                            fontSize={{ base: '2xl', sm: '3xl', md: '6xl' }}
                        >
                            <Highlight
                                query='Asset-Lite'
                                styles={{ color: "brand.500" }}
                            >
                                Asset-Lite Solution
                            </Highlight>
                        </Heading>
                        <Text color={'gray.50'} fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
                            Parkify is a cloud-based software solution. Maintenance and updates are managed automatically.
                            Parkify can be turned on without any disruption to your facility's service.
                        </Text>
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={3}
                            float={"right"}
                        >
                            <Button
                                onClick={onOpen}
                                variant={'outline'}
                                rounded="lg"
                                colorScheme="whiteAlpha"
                            >
                                Contact Us
                            </Button>
                        </Stack>
                    </Stack>
                </Container >
            </Box>

            <Box bg="gray.50">
                <Container maxW={'3xl'}>
                    <Stack
                        as={Box}
                        textAlign={'left'}
                        spacing={{ base: 8, md: 14 }}
                        py={12}
                    >
                        <Heading
                            fontWeight={700}
                            as="h2"
                            fontSize={{ base: '2xl', sm: '3xl', md: '6xl' }}
                        >
                            <Highlight
                                query='parking operator'
                                styles={{ color: "purple.500" }}
                            >
                                Seemless integration with your parking operator
                            </Highlight>
                        </Heading>
                        <Text fontSize={{ base: '1xl', sm: '2xl', md: '3xl' }}>
                            Parkify is designed to play nicely with your parking operator. Parking operations and
                            service continue to operate without additional complexity or training.
                        </Text>
                        <Stack
                            direction={{ base: 'column', md: 'row' }}
                            spacing={3}
                            float={"right"}
                        >
                            <Button
                                onClick={onOpen}
                                variant={'outline'}
                                rounded="lg"
                                colorScheme="purple"
                            >
                                Contact Us
                            </Button>
                        </Stack>
                    </Stack>
                </Container >
            </Box>
        </>
    );
}